<template>
  <div class="delivery-pay-result">
    <div class="container py-5">
      <div v-if="paySuccess">
        <h2 class="text-center mb-4">服務加購完成</h2>
        <h5 class="text-center mb-5">請至服務紀錄，送出衣物資料</h5>
        <div class="row no-gutters mb-5">
          <div class="col-auto pr-2">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M11.9974 3.01198L11.8038 3.89091L11.9974 3.01198C11.5709 2.91805 11.2402 3.10958 11.0639 3.26326C10.9043 3.40238 10.7926 3.57232 10.7202 3.69291C10.5768 3.93174 10.4194 4.27178 10.2504 4.63699C10.2434 4.65193 10.2365 4.66692 10.2295 4.68195L7.49821 10.5816C7.48913 10.6012 7.48008 10.6208 7.47105 10.6403C7.19853 11.2288 6.95285 11.7593 6.82276 12.2011C6.68401 12.6724 6.60759 13.2655 6.96389 13.8231C7.3202 14.3807 7.89054 14.5605 8.37646 14.6326C8.83208 14.7001 9.41673 14.7001 10.0652 14.7C10.0867 14.7 10.1083 14.7 10.1299 14.7H11.1V18.7299L11.1 18.7794C11.0999 19.1818 11.0999 19.5565 11.1297 19.8335C11.1447 19.9734 11.1747 20.1745 11.2611 20.3678C11.3565 20.5814 11.5762 20.8941 12.0026 20.988C12.4291 21.0819 12.7598 20.8904 12.9361 20.7367C13.0957 20.5976 13.2074 20.4277 13.2798 20.3071C13.4232 20.0683 13.5806 19.7282 13.7496 19.363L13.7705 19.318L16.5018 13.4184C16.5109 13.3987 16.5199 13.3792 16.5289 13.3597C16.8015 12.7712 17.0472 12.2407 17.1772 11.7989C17.316 11.3276 17.3924 10.7345 17.0361 10.1769L16.2777 10.6615L17.0361 10.1769C16.6798 9.61934 16.1095 9.4395 15.6235 9.36743C15.1679 9.29985 14.5833 9.29992 13.9348 9.29999C13.9133 9.3 13.8917 9.3 13.8701 9.3H12.9V5.27013C12.9 5.25357 12.9 5.23706 12.9 5.22059C12.9001 4.81816 12.9001 4.44347 12.8703 4.16649C12.8553 4.02663 12.8253 3.82549 12.7389 3.63218C12.6435 3.41865 12.4238 3.10591 11.9974 3.01198Z" stroke="#E3861A" stroke-width="1.8"/>
            </svg>
          </div>

          <div class="col">
            <p>
              本公司不會主動以電話、簡訊聯繫您更改付費方式及退款等。<br/>
              <br/>
              如遇問題可前往本站「聯絡我們」頁面提交表單，或以使用以下方式聯繫我們：<br/>
              <br/>
              客服信箱：service@walkingcloset.co<br/>
              客服LINE： ＠walkingcloset<br/>
              客服專線：02-7752-2510
            </p>
          </div>
        </div>

        <div class="text-center">
          <RouterLink :to="{ name: 'DeliveryItemList'}" class="btn btn-wdark py-3">至服務紀錄，送出衣物資料</RouterLink>
        </div>
      </div>

      <div v-if="!paySuccess">
        <h2 class="text-center mb-4">付款取消</h2>
        <div class="text-center">
          <RouterLink :to="{ name: 'DeliveryShop'}" class="btn btn-wdark py-3">回到商店清單</RouterLink>
        </div>
      </div>

    </div>

  </div>
</template>


<script>
import { mapActions } from 'vuex';

export default {
  name: 'DeliveryPayResult',
  data() {
    return {
      paySuccess: false,
    };
  },
  created() {
    this.paySuccess = this.$route.query.status === "0";
  },
  components: {
  },
  props: {
  },
  beforeDestroy() {
  },
  async mounted() {
  },
  computed: {
  },
  watch: {
  },
  methods: {
    ...mapActions(['showMsgModal', 'setBodyConfigShow', 'appendComponentBusy', 'clearComponentBusy', 'appendErrorMsg']),
  }
}
</script>

<style scoped>
  .btn.btn-wdark {
    background-color: #4D4D4D;
    color: white;
    width: 100%;
  }

  .btn.btn-wdark:hover, .btn.btn-wdark:active, .btn.btn-wdark:focus {
    background-color: #1d1d1d;
    color: white;
  }
</style>
